import store from './store';
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import theme from './theme';

import App from './App';

const queryClient = new QueryClient();

function EntryPoint() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default EntryPoint;
