import _ from 'lodash';
import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  baseURL: 'http://localhost:4000',
  timeout: 10000,
});

api.getAxie = async (axieId) => {
  const response = await api.get(`/axie/${axieId}`);

  return response.data;
};

api.dealsStatus = async (deals) => {
  const compactDeals = _.map(deals, ({ axieId, timestamp }) => ({
    axieId,
    timestamp,
  }));
  const response = await api.post('/dealsStatus', { deals: compactDeals });

  return response.data;
};

api.findDeals = async (auctions, criterias) => {
  const response = await api.post('/findDeals', { auctions, criterias });

  return response.data;
};

export default api;
