import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ids: [],
  map: {},
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    addDeal: (state, action) => {
      if (state.map[action.payload.id]) {
        state.ids = state.ids.filter((id) => id !== action.payload);
      }
      state.ids.push(action.payload.id);
      state.map[action.payload.id] = action.payload;
    },
    removeDeal: (state, action) => {
      if (state.map[action.payload]) {
        state.ids = state.ids.filter((id) => id !== action.payload);
        delete state.map[action.payload];
      }
    },
    updateDeal: (state, action) => {
      if (state.map[action.payload.id]) {
        state.map[action.payload.id] = action.payload;
      }
    },
  },
})

export const { addDeal, removeDeal, updateDeal } = dealsSlice.actions

export default dealsSlice.reducer
