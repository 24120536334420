import React from 'react';
import { useSelector } from 'react-redux'
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import DealItem from './DealItem';
import makeStyles from '@mui/styles/makeStyles';

const DealsList = ({ onAxieSelected }) => {
  const classes = useStyles();
  const dealsIds = useSelector((state) => state.deals.ids);

  return (
    <div className={classes.container}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemSize={100}
            itemCount={dealsIds.length}
            overscanCount={5}
          >
            {(props) => <DealItem {...props} onAxieSelected={onAxieSelected} />}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  }
}));

export default React.memo(DealsList);
