import store from '../store';
import { SCANNER_STATUS } from '../consts';
import { setLastRoninBlock, setStatus,setLastParsedBlock, incrementQueue, decrementQueue, setQueue, addLogInfo, addLogError } from '../slices/scanner';
import { blockListener, getBlockCreateAuctions } from './ronin';

export const startScanner = (onNewAuctions) => {
  const state = store.getState();
  if (state.scanner.status === SCANNER_STATUS.RUNNING) return;

  store.dispatch(setStatus(SCANNER_STATUS.RUNNING));

  const unsubscribe = blockListener(async (blockNumber) => {
    store.dispatch(setLastRoninBlock(blockNumber));
    store.dispatch(incrementQueue());
    try {
      const auctions = await getBlockCreateAuctions(blockNumber);
      store.dispatch(addLogInfo(`Parsed block: ${blockNumber}`));
      store.dispatch(setLastParsedBlock(blockNumber));
      onNewAuctions(auctions, blockNumber);
    } catch (err) {
      store.dispatch(addLogError(`Error parsing block ${blockNumber}`));
    }
    store.dispatch(decrementQueue());
  });

  return () => {
    store.dispatch(setStatus(SCANNER_STATUS.STOPPED));
    store.dispatch(setQueue(0));
    unsubscribe();
  }
};
