import React from 'react';

const getAxieUrl = (axieId) => `https://storage.googleapis.com/assets.axieinfinity.com/axies/${axieId}/axie/axie-full-transparent.png`;

const AxieImage = ({ axie, axieId, ...props }) => {
  return (
    <img {...props} src={getAxieUrl(axie?.id ?? axieId)} />
  );
};

export default React.memo(AxieImage);
