import { useState } from 'react';
import { Container, Stack, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import DealsList from './DealsList';
import DealDetail from './DealDetail';
import Scanner from './Scanner';

const Tracker = () => {
  const classes = useStyles();
  const [selectedAxieId, setSelectedAxieId] = useState();
  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Paper variant="outlined" square className={classes.left}>
        <Stack className={classes.leftStack}>
          <Scanner />
          <DealsList onAxieSelected={setSelectedAxieId} />
        </Stack>
      </Paper>
      <Paper className={classes.right} variant="outlined" square>
        <DealDetail axieId={selectedAxieId} />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  left: {
    height: '100vh',
    minWidth: 350,
  },
  leftStack: {
    height: '100vh',
  },
  dealsListContainer: {
    height: '100vh',
  },
  right: {
    width: '100%',
  },
}));

export default Tracker;
