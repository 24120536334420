import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

const SuspenseFallback = () => (
  <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default React.memo(SuspenseFallback);
