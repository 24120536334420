import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Paper, IconButton, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';

import AxieImage from '../../components/AxieImage';
import { DEAL_STATUS } from '../../consts'
import useAxie from '../../api/useAxie'
import { updateDeal, removeDeal } from '../../slices/deals'


const DealItem = ({ index, style, onAxieSelected }) => {
  const dispatch = useDispatch();
  const axieId = useSelector((state) => state.deals.ids[index]);
  console.log('DealItem', index, axieId);
  const deal = useSelector((state) => state.deals.map[axieId]);
  const { data: axie, isLoading } = useAxie(axieId, { enabled: !!axieId && deal?.status === DEAL_STATUS.ACTIVE, refetchInterval: 5000 });
  // const deal = useSelector((state) => {
  //   const id = state.deals.ids[index];
  //   return state.deals.map[id];
  // });

  const onRemove = () => {
    dispatch(removeDeal(axieId));
  };

  useEffect(() => {
    if (axie && !axie.auction) {
      dispatch(updateDeal(axieId, {
        id: axieId,
        status: axie.owner === ':ronin' ? DEAL_STATUS.SOLD_ME : DEAL_STATUS.SOLD_OTHER,
      }));
    }
  }, [axie, dispatch]);

  return (
    <Paper elevation={1} square style={style} onClick={() => onAxieSelected(axieId)}>
      <Grid container>
        <Grid item xs={4}>
          <AxieImage axieId={axieId} />
        </Grid>
        <Grid item xs={8}>
          {isLoading ? 'loading...' : `#${axieId} ${deal?.status}`}
          <IconButton aria-label="delete" onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 100,
  }
}));

export default React.memo(DealItem);
