import { createSlice } from '@reduxjs/toolkit'

import { SCANNER_STATUS, LOG_TYPES } from '../consts'

const initialState = {
  status: SCANNER_STATUS.STOPPED,
  lastRoninBlock: -1,
  lastParsedBlock: -1,
  queueSize: 0,
  logs: [],
}

const addLog = (state, log, type) => {
  state.logs.push({ at: Date.now(), log, type });
}

export const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLastRoninBlock: (state, action) => {
      state.lastRoninBlock = action.payload;
    },
    setLastParsedBlock: (state, action) => {
      if (action.payload > state.lastParsedBlock) {
        state.lastParsedBlock = action.payload;
      }
    },
    incrementQueue: (state) => {
      state.queueSize += 1;
    },
    decrementQueue: (state) => {
      state.queueSize -= 1;
    },
    setQueue: (state, action) => {
      state.queueSize = action.payload;
    },
    addLogInfo: (state, action) => {
      addLog(state, action.payload, LOG_TYPES.INFO);
    },
    addLogError: (state, action) => {
      addLog(state, action.payload, LOG_TYPES.ERROR);
    },
  },
})

// Action creators are generated for each case reducer function
export const { setStatus, setLastRoninBlock, setLastParsedBlock, incrementQueue, decrementQueue, setQueue, addLogInfo, addLogError } = scannerSlice.actions

export default scannerSlice.reducer
