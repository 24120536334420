import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { SCANNER_STATUS, DEAL_STATUS } from '../../consts';
import api from '../../api';
import { startScanner } from '../../services/scanner';
import { addDeal } from '../../slices/deals';
import store from '../../store';

const MIN_PRICE = 0.05;
const MAX_PRICE = 0.1;

// const DEFAULT_CRITERIAS = [{
//   transfers: {
//     periodHours: 12,
//     minTransfers: 2,
//     maxSoldUnderPrice: 1,
//     minSoldOverPrice: 1,
//   },
//   auctions: {
//     minAuctions: 2,
//     isCheaper: true,
//     minCheapestPerc: 0.9,
//   },
// }];

const DEFAULT_CRITERIAS = [{
  transfers: {
    periodHours: 12,
  },
}];

const processAuctions = async (auctions) => {
  const eligibleAuctions = [];
  _.forEach(auctions, (auction) => {
    if (auction.duration === 123 && auction.endingPrice > 10) return;
    if (auction.startingPrice < MIN_PRICE && auction.startingPrice > MAX_PRICE) return;
    eligibleAuctions.push(auction);
  });

  if (!eligibleAuctions.length) return;

  const deals = await api.findDeals(eligibleAuctions, DEFAULT_CRITERIAS);
  console.log('deals', deals);

  _.forEach(deals, (id) => {
    store.dispatch(addDeal({
      id,
      status: DEAL_STATUS.ACTIVE,
    }));
  })
};

const Scanner = () => {
  const classes = useStyles();
  const status = useSelector((state) => state.scanner.status);
  const lastRoninBlock = useSelector((state) => state.scanner.lastRoninBlock);
  const lastParsedBlock = useSelector((state) => state.scanner.lastParsedBlock);
  const unsubscribeRef = useRef();

  const onStartScanner = useCallback(() => {
    const unsubscribe = startScanner((auctions, blockNumber) => {
      console.log('NEW AUCTIONS', auctions, blockNumber);
      return processAuctions(auctions);
    });
    unsubscribeRef.current = unsubscribe;
  }, []);

  const onStopScanner = useCallback(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }
  }, []);

  return (
    <Paper elevation={2} square className={classes.container}>
      <Typography>Last Ronin Block: {lastRoninBlock}</Typography>
      <Typography>Last Parsed Block: {lastParsedBlock}</Typography>
      {status === SCANNER_STATUS.STOPPED && <PlayCircleIcon onClick={onStartScanner} />}
      {status === SCANNER_STATUS.RUNNING && <StopCircleIcon onClick={onStopScanner} />}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 100,
  },
}));

export default React.memo(Scanner);
