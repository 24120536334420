import _ from 'lodash';
import { ethers } from 'ethers';
const BigNumber = require('bignumber.js');

const parseEther = (ether, precision = 8) => parseFloat(new BigNumber(ether).shiftedBy(-18).precision(precision).toString());

export const iface = new ethers.utils.Interface([
  'function createAuction(uint8[] _tokenTypes, address[] _tokenAddresses, uint256[] _tokenNumbers, uint256[] _startingPrices, uint256[] _endingPrices, address[] _exchangeTokens, uint256[] _durations)',
  'event AuctionCreated(address _seller, uint256 _listingIndex, uint256[] _startingPrices, uint256[] _endingPrices, address[] _exchangeTokens, uint256[] _durations, uint256 _startingTimestamps)',
]);
export const provider = new ethers.providers.JsonRpcProvider({
  url: 'https://api.roninchain.com/rpc',
  timeout: 30000,
});

export const AXIE_CONTRACT = _.toUpper('0x32950db2a7164ae833121501c797d79e7b79d74c');
export const MARKETPLACE_CONTRACT = _.toUpper('0x213073989821f738a7ba3520c3d31a1f9ad31bbd');

const getParamValue = (param) => {
  if (param === undefined || param === null) return null;
  if (ethers.BigNumber.isBigNumber(param)) return param.toString();
  if (_.isArray(param)) return _.map(param, getParamValue);
  if (typeof param === 'string') return param;
  return param;
}

const processParams = (inputs, args) => {
  const params = _.reduce(inputs, (acc, input) => {
    acc[input.name] = getParamValue(args[input.name]);
    return acc;
  }, {});
  return params
};

const parseTransactionParams = (transaction) => {
  const parsed = iface.parseTransaction({ data: transaction.data }); // dá throw caso não seja uma função conhecida
  const params = processParams(parsed.functionFragment.inputs, parsed.args);

  return params;
};

const parseRoninTransaction = async (transaction, timestamp) => {
  const to = _.toUpper(transaction.to);
  if (to === MARKETPLACE_CONTRACT) {
    try {
      // já faz parse da transaction. Se não for algo que precisar rastrear, já sai aqui
      const params = parseTransactionParams(transaction);

      await transaction.wait(1, 30000); // dá throw caso não esteja confirmada
      // verifica se é createAuction de Axie (não é de Land ou Item)
      const tokenAddress = _.toUpper(params?._tokenAddresses?.[0]);
      if (tokenAddress !== AXIE_CONTRACT) return;

      return {
        timestamp,
        duration: parseInt(params._durations[0], 10),
        endingPrice: parseEther(params._endingPrices[0]),
        startingPrice: parseEther(params._startingPrices[0]),
        axieId: parseInt(params._tokenNumbers[0], 10),
      };
    } catch (err) {
      if (err.code !== 'INVALID_ARGUMENT' && err.code !== 'CALL_EXCEPTION' && err.code !== 'BUFFER_OVERRUN') {
        console.error('ERROR PARSING TRANSACTION', transaction.hash);
        console.error(err.code, err.message);
        console.error(transaction);
        throw err;
      }
    }
  }
};

export const blockListener = (fn) => {
  provider.on('block', fn);

  return () => provider.off('block', fn);
};

export const getBlockCreateAuctions = async (blockNumber) => {
  const block = await provider.getBlockWithTransactions(blockNumber);
  const auctions = [];

  await Promise.all(_.map(block.transactions, async (transaction) => {
    try {
      const parsedTransaction = await parseRoninTransaction(transaction, block.timestamp);
      if (parsedTransaction) {
        auctions.push(parsedTransaction);
      }
    } catch(err) {}
  }));

  return auctions;
};

export default ethers;
