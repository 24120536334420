import "./App.css";

import Tracker from "./screens/Tracker";

function App() {
  return (
    <Tracker />
  );
}

export default App;
