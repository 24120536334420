import { configureStore } from '@reduxjs/toolkit';

import dealsReducer from './slices/deals';
import scannerReducer from './slices/scanner';

export default configureStore({
  reducer: {
    deals: dealsReducer,
    scanner: scannerReducer,
  },
});
