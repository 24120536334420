import { useCallback } from 'react';
import { useQuery } from 'react-query';

import api from './index';

const useAxie = (axieId, options) => {
  const getAxie = useCallback(() => {
    return api.getAxie(axieId);
  }, [axieId]);
  return useQuery(`axie/${axieId}`, getAxie, options);
};

export default useAxie;
