export const SCANNER_STATUS = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
};

export const DEAL_STATUS = {
  ACTIVE: 'ACTIVE',
  SOLD_ME: 'SOLD_ME',
  SOLD_OTHER: 'SOLD_OTHER',
};

export const LOG_TYPES = {
  INFO: 'INFO',
  ERROR: 'ERROR',
};
