import React from 'react';
import { Grid, Card, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const DealDetail = ({ axieId }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Paper elevation={1} square>
        DEAL DETAIL
        {axieId}
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
}));

export default React.memo(DealDetail);
